import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6973f6b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "UserGroup" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_EditTable = _resolveComponent("EditTable")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      ref: "formRef",
      model: _ctx.form,
      rules: _ctx.rules,
      "label-width": "100px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: "用户组名称",
          prop: "name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.form.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.name) = $event)),
              placeholder: "请输入用户组名称",
              maxlength: "40",
              clearable: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "关系条件",
          prop: "relationCondition"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: _ctx.form.relationCondition,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.relationCondition) = $event)),
              placeholder: "请选择关系条件",
              style: {"width":"100%"}
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'relationCondition'}), (item) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: item.value,
                    label: item.label,
                    value: item.value
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    _createVNode(_component_el_tabs, {
      modelValue: _ctx.activeTab,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.activeTab) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: "按机构设置",
          name: "first"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_EditTable, _mergeProps({
              ref: el=>_ctx.refMap.set('corpDetails',el)
            }, _ctx.cardDetailParam('corpDetails'), { "parent-inst": this }), {
              tbCols: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  label: "名称",
                  prop: "F_NAME"
                })
              ]),
              _: 1
            }, 16)
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, {
          label: "按部门设置",
          name: "second"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_EditTable, _mergeProps({
              ref: el=>_ctx.refMap.set('deptDetails',el)
            }, _ctx.cardDetailParam('deptDetails'), { "parent-inst": this }), {
              tbCols: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  label: "名称",
                  prop: "F_NAME"
                })
              ]),
              _: 1
            }, 16)
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, {
          label: "按人员设置",
          name: "third"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_EditTable, _mergeProps({
              ref: el=>_ctx.refMap.set('personDetails',el)
            }, _ctx.cardDetailParam('personDetails'), { "parent-inst": this }), {
              tbCols: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  label: "名称",
                  prop: "F_NAME"
                })
              ]),
              _: 1
            }, 16)
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, {
          label: "按角色设置",
          name: "fourth"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_EditTable, _mergeProps({
              ref: el=>_ctx.refMap.set('roleDetails',el)
            }, _ctx.cardDetailParam('roleDetails'), { "parent-inst": this }), {
              tbCols: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  label: "名称",
                  prop: "F_NAME"
                })
              ]),
              _: 1
            }, 16)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_el_dialog, {
      class: "userGroupDialog",
      draggable: "",
      fullscreen: false,
      title: "选择弹出框",
      modelValue: _ctx.dialogVisible,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dialogVisible) = $event)),
      onOpened: _ctx.opened,
      "append-to-body": ""
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_el_button, {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.dialogVisible = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode("取 消")
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.corpDeptSureHandler()))
          }, {
            default: _withCtx(() => [
              _createTextVNode("确 定")
            ]),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        (_ctx.curOperGrid=='corpDetails')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_el_tree, {
                ref: el=>_ctx.refMap.set('corpTreeRef',el),
                data: _ctx.corpDetailData.treeData,
                "show-checkbox": "",
                "node-key": "value",
                "default-expand-all": "",
                "default-checked-keys": _ctx.corpDetailData.selectItems,
                props: _ctx.corpDetailData.defaultProps,
                "check-strictly": true,
                "expand-on-click-node": false,
                "check-on-click-node": true
              }, null, 8, ["data", "default-checked-keys", "props"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.curOperGrid=='deptDetails')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_el_container, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_aside, { width: "300px" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_tree, {
                        ref: el=>_ctx.refMap.set('corpTreeRef',el),
                        data: _ctx.corpDetailData.treeData,
                        "node-key": "value",
                        "default-expand-all": "",
                        props: _ctx.corpDetailData.defaultProps,
                        "expand-on-click-node": false,
                        onNodeClick: _ctx.corpClickChange
                      }, null, 8, ["data", "props", "onNodeClick"])
                    ]),
                    _: 1
                  }),
                  _withDirectives((_openBlock(), _createBlock(_component_el_main, { style: {"padding":"0px"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_tree, {
                        ref: el=>_ctx.refMap.set('deptTreeRef',el),
                        data: _ctx.deptDetailData.treeData,
                        "show-checkbox": "",
                        "node-key": "value",
                        "default-expand-all": "",
                        props: _ctx.deptDetailData.defaultProps,
                        "check-strictly": true,
                        onCheckChange: _ctx.deptCheckChange
                      }, null, 8, ["data", "props", "onCheckChange"])
                    ]),
                    _: 1
                  })), [
                    [_directive_loading, _ctx.deptLoading]
                  ])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue", "onOpened"])
  ]))
}