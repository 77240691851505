import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7e3362a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "templateCard" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_cascader = _resolveComponent("el-cascader")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      ref: "formRef",
      model: _ctx.form,
      rules: _ctx.rules,
      "label-width": "100px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: "模块",
          prop: "modelId"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_cascader, {
              modelValue: _ctx.form.modelId,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.modelId) = $event)),
              clearable: "",
              placeholder: "请选择模块",
              style: {"width":"100%"},
              options: _ctx.billTypeTreeData
            }, null, 8, ["modelValue", "options"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "名称",
          prop: "name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.form.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.name) = $event)),
              maxlength: "50",
              placeholder: "请输入名称",
              clearable: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "说明",
          prop: "desc"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              type: "textarea",
              modelValue: _ctx.form.desc,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.desc) = $event)),
              maxlength: "100",
              placeholder: "请输入说明",
              clearable: ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "是否启用流程",
          prop: "useFlow"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_switch, {
              modelValue: _ctx.form.useFlow,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.useFlow) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _withDirectives(_createVNode(_component_el_form_item, {
          label: "流程版本",
          prop: "flowVersion"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input_number, {
              modelValue: _ctx.form.flowVersion,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.flowVersion) = $event)),
              disabled: true
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 512), [
          [_vShow, _ctx.form.id]
        ])
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ]))
}