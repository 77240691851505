import BaseBean from "@/utils/BaseBean";
interface INodeProps {
    name:string//节点显示的文本名称
    stepCode:string//节点的编号
    modifyAttach:boolean//是否可以修改附件
    delBill:boolean//是否可以删除单据
    fetchBackBill:boolean//是否可以取回单据
    modifyBill:boolean//是否可以修改单据
    chooseOperator:boolean//是否可以选择经办人
    rollBackType:string//驳回方式
    userGroup:string//用户组
    filterRole:any//过滤规则
    filterRuleSuper:any//过滤规则下的二级过滤下拉框
    sign:any//会签信息
}
export interface IDefineCardDataObj {
    utilInst:DefineCardUtil
    userComboRef: any//基本人员组下拉引用
    currentOperNode: any//当前正在操作的节点，主要用于弹出框设置属性之后要回填节点
    currentOperConnectNode: any//当前正在操作的节点，主要用于弹出框设置属性之后要回填节点
    dialogVisible:boolean//节点属性设置弹出框显示/影藏控制字段
    conditionVisible:boolean//转移属性设置弹出框显示/影藏控制字段
    helpVisible:boolean//使用帮助弹出框显示/影藏控制字段
    showSuperFilterRole:boolean//过滤规则选择之后，会控制是否显示第二季过滤，该字段就是二级过滤的显示/影藏控制字段
    //流程图有时候不显示，需要点击重置按钮进行再次加载才能显示（没有找到原因），
    //所以进入页面的时候加一个提示，但点击重置的时候不应该再次提示，所以用一个变量充当开关控制一下。
    msgFlag:boolean
    mainHeight:number//流程设计区域的高度(在onMounted中会动态计算改变)
    //节点属性对象
    nodeProps:INodeProps
    //流程条件属性对象
    conditionProps:any
    //催办设置
    cbProps:any
    //节点图像集合
    flowImages:any
    //按钮点击状态
    btnStatus:any
    //卡片传给dialog的初始化参数
    compParams: any
    //表单属性
    form: any
}
export default class DefineCardUtil extends BaseBean{
    public dataObj:IDefineCardDataObj;

    constructor(proxy:any,dataObj:IDefineCardDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //打开节点属性设置弹出框之后，为弹出框里面的属性赋值
    public initNodePropDialog(options:any):void{
        if(options.selfId=='makeBill'){//如果当前操作的是制单节点，则过滤规则的某些项应该禁用
            this.dataObj.nodeProps.filterRole.options.forEach((item:any,index:number)=> {
                if(index>=4)item.disabled=true;
            });
        }else{//不是制单节点，则可以使用任何过滤规则
            this.dataObj.nodeProps.filterRole.options.forEach((item:any)=>item.disabled=false);
        }
        if(options.nodeProperty){//编辑节点的时候，如果节点设置过属性值，需要把属性值赋值到弹出框中
            let tempNodeProperty=options.nodeProperty;
            //很奇怪，第一次打开的时候，如果节点设置过属性值，那么该属性值为字符串，所以需要把它转为对象好操作一点，
            //但是第二次打开的时候就变为对象了，因此在这里要判断一下类型，为对象就不能再次转换了
            if('string'==typeof(tempNodeProperty))tempNodeProperty=JSON.parse(tempNodeProperty);
            this.dataObj.nodeProps.name=tempNodeProperty.name;
            this.dataObj.nodeProps.stepCode=tempNodeProperty.stepCode;
            this.dataObj.nodeProps.modifyAttach=tempNodeProperty.modifyAttach;
            this.dataObj.nodeProps.delBill=tempNodeProperty.delBill;
            this.dataObj.nodeProps.fetchBackBill=tempNodeProperty.fetchBackBill;
            this.dataObj.nodeProps.modifyBill=tempNodeProperty.modifyBill;
            this.dataObj.nodeProps.chooseOperator=tempNodeProperty.chooseOperator;
            this.dataObj.nodeProps.rollBackType=tempNodeProperty.rollBackType;
            this.dataObj.nodeProps.userGroup=tempNodeProperty.userGroup;
            this.dataObj.nodeProps.filterRole.value=tempNodeProperty.filterRole;
            this.dataObj.nodeProps.filterRuleSuper.value=tempNodeProperty.filterRuleSuper;
            if(tempNodeProperty.sign)this.dataObj.nodeProps.sign=tempNodeProperty.sign;
            let filterRole=tempNodeProperty.filterRole;
            if(filterRole==2 ||filterRole==3 ||filterRole==5 ||filterRole==6)this.dataObj.showSuperFilterRole=true;
        }else{//编辑节点的时候，如果该节点没有设置过属性值，需要在弹出框打开的时候，把nodeProps还原，因为有可能上一个节点已经改变了nodeProps的值了
            this.dataObj.nodeProps.name='';
            this.dataObj.nodeProps.stepCode='';
            this.dataObj.nodeProps.modifyAttach=(options.selfId=='makeBill');
            this.dataObj.nodeProps.delBill=true;
            this.dataObj.nodeProps.fetchBackBill=(options.selfId=='makeBill');
            this.dataObj.nodeProps.modifyBill=(options.selfId=='end');
            this.dataObj.nodeProps.chooseOperator=true;
            this.dataObj.nodeProps.rollBackType='1';
            this.dataObj.nodeProps.userGroup='';
            this.dataObj.nodeProps.filterRole.value='';
            this.dataObj.nodeProps.filterRuleSuper.value='';
            this.dataObj.nodeProps.sign= {
                flag:false,//是否启用会签
                agreeNum:1,//同意人数
                controlType:"0",//会签流转控制类别
                agreePercent:0,//同意百分比
                disagreeNum:100,//不同意人数
                disagreePercent:100,//不同意百分比
            };
            this.dataObj.showSuperFilterRole=false;//不管有没有设置节点属性，关闭的时候都要把showSuperFilterRole还原，否则打开另外一个节点的时候，有可能就显示出来了
        }
        this.dataObj.currentOperNode=options;//存储当前正在操作的节点
        this.dataObj.dialogVisible=true;
    }
    //节点属性弹出框确认事件
    public nodeDialogSure():void{
        if(this.dataObj.currentOperNode){
            //重新设置节点显示的文本
            if(this.utils.UtilPub.isNotEmpty(this.dataObj.nodeProps.name)){
                let attr = { text: this.dataObj.nodeProps.name};
                if(this.dataObj.currentOperNode.childNode){//有可能是点击图片打开的节点属性设置弹出框
                    this.dataObj.currentOperNode.childNode.attr(attr);
                }else{//有可能是点击图片上的文本组件打开的节点属性设置弹出框
                    this.dataObj.currentOperNode.attr(attr);
                }
            }
            //不能直接将nodeProps赋值给变量nodeProperty，否则变量改变了，那么nodeProperty也会改变（？？）
            let nodeProperty = Object.assign({}, this.dataObj.nodeProps) as any;
            nodeProperty.filterRole=this.dataObj.nodeProps.filterRole.value;
            nodeProperty.filterRuleSuper=this.dataObj.nodeProps.filterRuleSuper.value;
            if(this.dataObj.currentOperNode.parentNode)this.dataObj.currentOperNode=this.dataObj.currentOperNode.parentNode;
            this.dataObj.currentOperNode.nodeProperty=nodeProperty;
        }
        this.dataObj.showSuperFilterRole=false;//不管有没有设置节点属性，关闭的时候都要把showSuperFilterRole还原，否则打开另外一个节点的时候，有可能就显示出来了
        this.dataObj.dialogVisible=false;
    }
    //打开流程条件属性弹出框之后，为弹出框里面的属性赋值
    public initConditionPropDialog(options:any):void{
        if(options.nodeProperty) {//编辑节点的时候，如果节点设置过属性值，需要把属性值赋值到弹出框中
            let tempNodeProperty= options.nodeProperty;
            //很奇怪，第一次打开的时候，如果节点设置过属性值，那么该属性值为字符串，所以需要把它转为对象好操作一点，
            //但是第二次打开的时候就变为对象了，因此在这里要判断一下类型，为对象就不能再次转换了
            if('string'==typeof(tempNodeProperty))tempNodeProperty=JSON.parse(tempNodeProperty);
            this.dataObj.conditionProps.name=tempNodeProperty.name;
            this.dataObj.conditionProps.condition=tempNodeProperty.condition;
            this.dataObj.conditionProps.desc=tempNodeProperty.desc;
        }else{
            this.dataObj.conditionProps.name='';
            this.dataObj.conditionProps.condition='';
            this.dataObj.conditionProps.desc='';
        }
        this.dataObj.currentOperConnectNode=options;
        this.dataObj.conditionVisible=true;
    }
    //流程条件弹出框确认事件
    public conditionDialogSure():void{
        if(''!=this.dataObj.currentOperConnectNode){
            //不能直接将conditionProps赋值给变量nodeProperty，否则变量改变了，那么nodeProperty也会改变（？？）
            let nodeProperty = Object.assign({}, this.dataObj.conditionProps);
            nodeProperty.name=this.dataObj.conditionProps.name;
            nodeProperty.condition=this.dataObj.conditionProps.condition;
            nodeProperty.desc=this.dataObj.conditionProps.desc;
            //如果有选择条件，则为该条件设置赋上属性，否则不管
            if(nodeProperty.condition){
                this.dataObj.currentOperConnectNode.nodeProperty=nodeProperty;
            }else{
                this.dataObj.currentOperConnectNode.nodeProperty='';
            }
        }
        this.dataObj.conditionVisible=false;
    }
}