import TemplateCard from './TemplateCard.vue';
import DefineCard from "../define/DefineCard.vue";
import ConditionList from "@/views/sysviews/flow/condition/ConditionList.vue";
import {ref, reactive, toRefs, onMounted, defineComponent, getCurrentInstance, computed, inject} from 'vue';

export default defineComponent({
    name: "UserGroupList",
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let mainComp = inject('mainComp') as any;
        let dataObj=reactive({
            pageListRef:null,
            corpTreeData: [],
            catalogData:[],
            pageList: {
                queryParam: {},
                modelComp: TemplateCard,
                modelMethod: utils.Api.buildUrl("/template/pageData")
            }
        })
        onMounted(()=>{
            init();
        })
        //---------------------------computed------------
        const comboSelect=computed(()=>{
            return (params:any) => {
                return dataObj.catalogData;
            }
        })

        const init=async()=>{
            dataObj.corpTreeData = await utils.Api.corpTreeData();
        }
        const gridLoaded=(res:any)=>{
            if(res.catalogData)dataObj.catalogData=JSON.parse(res.catalogData);
        }
        // //----------------列表数据表格的编辑、删除和角色授权
        const showModule=async(options:any)=>{
            options=Object.assign({},{
                ownerComp:dataObj.pageListRef
            },options);
            let vm = await utils.DialogApp.create(options);
            vm.dialogVisible = true;
        }
        const flowDesignHandler=(row:any)=>{
            showModule({addOrEdit:'edit',id:row.F_ID,dialogDiv:'flowDesignDialog',modelComp:DefineCard,title: row["F_NAME"]+"流程设计"});
        }
        const conditionHandler=async(row:any)=>{
            let options= {
                contentHeight:'',
                fullscreen:true,
                proxy:proxy,
                title:row["F_NAME"]+'流程条件',
                dialogDiv:'conditionList',
                mainComp:mainComp,
                templateId:row.F_ID,
                modelId:row.F_MODEL_ID,
                modelComp:ConditionList
            };
            await utils.UtilPub.openDialog(options)
        }
        return{
            ...toRefs(dataObj),comboSelect,init,gridLoaded,showModule,flowDesignHandler,conditionHandler
        }
    },


    components: {}
});