import {ref, computed, getCurrentInstance, reactive, toRefs, onMounted, defineComponent, provide} from 'vue';

export default defineComponent({
    name: 'ConditionCard',
    title: "流程条件",
    modelType:'card',
    fullscreen: false,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('EditTableOwner', proxy);
        let dataObj=reactive({
            disabled:false,
            fields:[],//字段
            formRef:null,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                modelPath: utils.Api.buildUrl("/condition"),
                hsDetails: true,
                details:['conditionDetail']
            },
            //表单属性
            form: {} as any,
            //表单验证规则
            rules: utils.UtilPub.commonFormValidRule([{name:'请输入条件名称'},{type:'请选择关系'}])
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:string) => {
                if('type'==params){
                    return [
                        { value: "1", label: "并且" },{ value: "2", label: "或者" }
                    ]
                }else if('calType'==params){
                    return [
                        { value: 1, label: "小于" },{ value: 2, label: "大于" },
                        { value: "3", label: "小于等于" },{ value: "4", label: "大于等于" },
                        { value: "5", label: "等于" },{ value: "6", label: "不等于" }
                    ];
                }else if('fields'==params){
                    return dataObj.fields;
                }
                return []
            }
        })
        //明细子表格的初始化参数
        const cardDetailParam=computed(()=>{
            return (detailType:string) => {
                return {
                    detailParam: {
                        readOnly:dataObj.disabled,
                        canPage: false,
                        queryParam: {
                            detailType: detailType
                        },
                        modelMethod: utils.Api.buildUrl("/condition/detail?t="+Math.random()*1000)
                    }
                }
            }
        })
        //打开弹出框的回调事件
        const beforeOpen=async(data:any,addOrLoad:string,cardEngine:any)=>{
            //把从列表带过来的templateId给设置上，方便保存到后台
            dataObj.form.templateId=cardEngine.engineParams.queryParam.templateId;
            let modelUrl='/'+cardEngine.engineParams.queryParam.modelId;
            let res = await utils.Api.conditionFields( {templateId: dataObj.form.templateId,modelUrl:modelUrl});
            dataObj.fields=res.fields;
        }
        const beforeSaveHandler=()=>{
            //保存之前，校验整个保存数据是否合法
            return true;
        }
        //明细表格结束编辑某一行的时候，给下拉框设置一个显示文本，方便不是编辑状态的情况下显示
        const beforeEndEdit=(options:any)=>{
            options.row.F_FIELD_NAME = dataObj.refMap.get('fieldsSelectRef').states.selected.currentLabel;//字段下拉框不编辑状态下的显示名称
            options.row.F_TYPE_NAME = dataObj.refMap.get('typeSelectRef').states.selected.currentLabel;//运算关系下拉框不编辑状态下的显示名称
            dataObj.refMap.get('conditionDetail').setRowData(options.row, options.index);
            return true;
        }
        return{
            ...toRefs(dataObj),comboSelect,cardDetailParam,beforeOpen,beforeSaveHandler,beforeEndEdit
        }
    },
    components: {}
});