import flowDesign from './jh.flow';
import DefineCardUtil,{IDefineCardDataObj} from "@/views/sysviews/flow/define/ts/defineCardUtil";
import UserGroupList from "@/views/sysviews/flow/userGroup/UserGroupList.vue";
import {reactive,toRefs,onBeforeMount,onMounted,onBeforeUnmount,defineComponent,getCurrentInstance,computed} from 'vue';
import start_task from '../../../../../assets/flow/start_task.png';
import must_task from '../../../../../assets/flow/must_task.png';
import user_task from '../../../../../assets/flow/user_task.png';
import end_task from '../../../../../assets/flow/end_task.png';
import exe_task from '../../../../../assets/flow/exe_task.png';
export default defineComponent({
    name: 'DefineCard',
    title: "流程定义",
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;

        let dataObj:IDefineCardDataObj=reactive<IDefineCardDataObj>({
            utilInst:{} as any,
            userComboRef: {} as any,//基本人员组下拉引用
            currentOperNode: {}as any,//当前正在操作的节点，主要用于弹出框设置属性之后要回填节点
            currentOperConnectNode: '',//当前正在操作的节点，主要用于弹出框设置属性之后要回填节点
            dialogVisible:false,//节点属性设置弹出框显示/影藏控制字段
            conditionVisible:false,//转移属性设置弹出框显示/影藏控制字段
            helpVisible:false,//使用帮助弹出框显示/影藏控制字段
            showSuperFilterRole:false,//过滤规则选择之后，会控制是否显示第二季过滤，该字段就是二级过滤的显示/影藏控制字段
            //流程图有时候不显示，需要点击重置按钮进行再次加载才能显示（没有找到原因），
            //所以进入页面的时候加一个提示，但点击重置的时候不应该再次提示，所以用一个变量充当开关控制一下。
            msgFlag:false,
            mainHeight:400,//流程设计区域的高度(在onMounted中会动态计算改变)
            //节点属性对象
            nodeProps:{
                name:'',//节点显示的文本名称
                stepCode:'',//节点的编号
                modifyAttach:false,//是否可以修改附件
                delBill:true,//是否可以删除单据
                fetchBackBill:false,//是否可以取回单据
                modifyBill:false,//是否可以修改单据
                chooseOperator:true,//是否可以选择经办人
                rollBackType:'1',//驳回方式
                userGroup:'',//用户组
                filterRole:{//过滤规则
                    options: [
                        {value: '0',label: '当前流程发起者',disabled: false}, {value: '1',label: '当前流程发起者所属角色人员',disabled: false},
                        {value: '2',label: '当前流程发起者所属部门',disabled: false}, {value: '3',label: '当前流程发起者所属机构',disabled: false},
                        {value: '4',label: '上一步骤提交人',disabled: false}, {value: '5',label: '上一步骤提交人所属部门',disabled: false},
                        {value: '6',label: '上一步骤提交人所属机构',disabled: false}, {value: '7',label: '上一步骤提交人所属角色人员',disabled: false}
                    ],
                    value: ''
                },
                filterRuleSuper:{//过滤规则下的二级过滤下拉框
                    options: [{value: '1',label: '上一级'}],
                    value: ''
                },
                sign:{
                    flag:false,//是否启用会签
                    controlType:"0",//会签流转控制类别
                    agreeNum:1,//同意人数
                    agreePercent:0,//同意百分比
                    disagreeNum:100,//不同意人数
                    disagreePercent:100,//不同意百分比
                }
            },
            //流程条件属性对象
            conditionProps:{
                name:'',
                condition:'',
                desc:''
            },
            //催办设置
            cbProps:{
                visible:false,
                desc:''
            },
            //节点图像集合
            flowImages:{
                exe_task: exe_task,//正在执行的节点
                startTaskImg: start_task,//开始节点图片
                mustTaskImg: must_task,//必须有的节点图片
                userTaskImg: user_task,//用户自定义节点图片
                endTaskImg: end_task//结束节点图片
            },
            //按钮点击状态
            btnStatus:{
                connectionClick:false, //点击画布的时候，判断是否已经点击过连线按钮，如果点击过表示要在画布上连线两个节点
                nodeClick:false,//是否点击过画布中已经存在的任何一个节点（连线的时候用）
                taskClick:false//是否点击过画布顶部的任务按钮
            },
            //卡片传给dialog的初始化参数
            compParams: {
                modelPath: utils.Api.buildUrl("/define"),
                // contentHeight:"800px",
                hsDetails: false
            },
            //表单属性
            form: {
                xml:'',
                templateId:'',
                saveData:{}
            } as any
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new DefineCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            // dataObj.mainHeight=(window.innerHeight-154);
            dataObj.mainHeight=(window.innerHeight-174);
        })
        onBeforeUnmount(()=>{
           flowDesign.closeContextMenu();
        })
        //---------------------------computed---------------------------
        const comboGridParam=computed(()=>{
            return (params:any) => {
                if('userGroup'==params.type){//用户组
                    return {
                        showToolBar:true,
                        params:params,
                        modelMethod: utils.Api.buildUrl("/define/combo")
                    }
                }else if('condition'==params.type){//流程条件
                    return {
                        params:params,
                        modelMethod: utils.Api.buildUrl("/define/combo")
                    }
                }
            };
        })

        //单据在查看审批流程的时候会传入一个参数叫做otherParams（详情参看BillEngineHelper的flowDesignHandler处），
        //该参数在这里被处理之后，将会传入后台DefineController，用于判断是否显示流程定义头部的按钮组。
        //因为通过templateList那里点击流程设计也会打开流程定义，这种方式打开的流程定义，头部是需要显示对应按钮的，那么怎么区分呢，
        //通过templateList那里点击流程设计打开流程定义，是不会传入otherParams参数的，这里就没有任何东西传入后台，所以就区分开来了。
        const setParam=(engine:any)=>{
            return engine.engineParams.otherParams;
        }
        //打开弹出框的回调事件
        const beforeOpen=async(data:any,addOrLoad:string,engine:any)=>{
            dataObj.form.templateId=engine.engineParams.id;
            flowDesign.initDesign({inst:proxy,engine:engine,data:data});//根据后台数据data，画出流程图
            // setTimeout(()=>{//当有dialog动画的时候，最好放到setTimeout，否则正在执行动画，但是消息就弹出来了，没有动画的时候，可以考虑不要setTimeout
            //     if(!dataObj.msgFlag){//参见dataObj中对msgFlag的描述
            //         proxy.$message({
            //             duration:5000,
            //             showClose:true,
            //             dangerouslyUseHTMLString: true,
            //             message: "<p style='color: red'>如果流程未显示，请点击重置按钮！</p>"
            //         });
            //         dataObj.msgFlag=!dataObj.msgFlag;
            //     }
            // },1000)
        }

        const beforeSaveHandler=(inst:any)=>{
            dataObj.form.xml=flowDesign.getSaveData();//把流程设计里面的节点处理成为数据，赋值给form的xml，然后保存
            if(dataObj.form.xml){//如果有节点没有按规则连线，则不能保存成功
                return true;
            }else{
                return false;
            }
        }
        //重写头部的saveHandler方法，因为该模块没有el-form表单，于是没有验证功能
        const saveHandler=async()=>{
            await proxy.engine.engineUtil.doSave({});//调用cardEngine的doSave方法
            flowDesign.setConnStatus(false);
        }
        //画布的点击事件
        const mousedown=async (e:any)=>{
            //点击画布的时候，判断是否已经点击过任务按钮，如果点击过表示要在画布上画任务节点
            if(dataObj.btnStatus.taskClick){
                await flowDesign.drawUserTaskNode({x:e.clientX,y:e.clientY,text:'任务'})
                dataObj.btnStatus.taskClick=false;
            }
            //点击画布的时候，判断是否已经点击过连线按钮，如果点击过表示要在画布上连线两个节点
            if(dataObj.btnStatus.connectionClick){
                flowDesign.setConnStatus(false);
                dataObj.btnStatus.connectionClick=false;
            }
        }

        //顶部任务按钮点击事件
        const taskHandler=()=>{
            //把任务按钮点击状态激活
            dataObj.btnStatus.taskClick=true;
            //要新画一个任务节点，把画布中节点的点击状态关闭（关闭连线相关资源）
            dataObj.btnStatus.nodeClick=false;
            flowDesign.setConnStatus(false);
        }

        //顶部连线按钮点击事件
        const connectionHandler=()=>{
            flowDesign.setConnStatus(true);
            //要连线，就得关闭任务点击状态
            dataObj.btnStatus.taskClick=false;
            //点击连线，需要把节点状态关闭，重新点击一个开始节点，再点击一个结束节点进行两个节点连线
            dataObj.btnStatus.nodeClick=false;
        }


        //过滤规则改变事件（根据值决定是否显示过滤规则的二级过滤下拉框）
        const ruleChange=(value:number)=>{
            if(value==2 ||value==3 ||value==5 ||value==6){
                dataObj.showSuperFilterRole=true;
            }else{
                dataObj.showSuperFilterRole=false;
                dataObj.nodeProps.filterRuleSuper.value='';
            }
        }

        //打开节点属性设置弹出框，设置当前正在操作的节点(编辑或双击节点的时候调用，所以会把当前proxy传入到jh.flow.ts中)
        const openNodeDialog=(options:any)=>{
            dataObj.utilInst.initNodePropDialog(options);
        }

        //节点弹出编辑框确认事件
        const nodeDialogSure=()=>{
            dataObj.utilInst.nodeDialogSure();
        }
        //节点弹出编辑框取消事件
        const nodeDialogCancel=()=>{
            dataObj.showSuperFilterRole=false;
            dataObj.dialogVisible = false;
        }

        //打开流程条件属性设置弹出框
        const openConditionNodeDialog=(options:any)=>{
            dataObj.utilInst.initConditionPropDialog(options);
        }
        //流程条件弹出框确认事件
        const conditionDialogSure=()=>{
            dataObj.utilInst.conditionDialogSure();
        }
        //打开催办弹出框
        const openCbDialog=(options:any)=>{
            dataObj.cbProps.visible=true;
        }
        //催办确认
        const cbDialogSure=()=>{
            //console.log(proxy.engine.engineParams.otherParams);
            dataObj.cbProps.visible=false;
        }
        //基本人员组下拉框顶部的编辑按钮
        const editUserGroupHandler=async(params:any)=>{
            await utils.UtilPub.openDialog({title:"用户组列表",contentHeight:'',fullscreen:true,modelComp:UserGroupList,proxy:proxy});
        }

        //帮助
        const helpHandler=()=>{
            dataObj.helpVisible=true;
        }
        return{
            ...toRefs(dataObj),comboGridParam,setParam,beforeOpen,beforeSaveHandler,saveHandler,mousedown,taskHandler,
            connectionHandler,ruleChange,openNodeDialog,nodeDialogSure,nodeDialogCancel,openConditionNodeDialog,openCbDialog,cbDialogSure,
            conditionDialogSure,editUserGroupHandler,helpHandler
        }
    },
    components: {}
});

